<template>
  <div class="hello">
    <h1>Veranstaltungen ({{ numberOfEvents }})</h1>

  <table class="table table-bordered table-hover table-striped">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Id</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="event in events" :key="event.id">
          <td>{{ event.id }}</td>
          <th scope="row">
            <router-link
              :to="{ name: 'structure', params: { group: event }}"
            >{{ event.name }}</router-link>&nbsp;
            <!--<b-button class="btn btn-warning" @click="editGroup(event)">o</b-button>&nbsp;
            <b-button
              class="btn btn-danger"
              icon
              v-confirm="{
                        loader: false,
                        ok: dialog => deleteGroup(dialog, event),
                        cancel: doNothing,
                        message: group.name + ' wirklich löschen?'}"
            >X</b-button>-->
          </th>
          <!--<td>{{ group.jobCount }}</td>
          <td>{{ (group.jobCount - group.assignmentCount) }} </td>-->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { APIService } from "../APIService";

const apiService = new APIService();

export default {
  name: "Events",

  props: {
    msg: String
  },

  components: {},

  data() {
    return {
      itemToDelete: null,
      fields: [
        { key: "id", sortable: true },
        {
          key: "name",
          sortable: true
        },
        { key: "actions", label: "Aktionen" }
      ],
      headVariant: "dark",
      filterOn: ["id", "name"],
      events: [],
      numberOfEvents: 0,
      totalRows: 0,
      perPage: 25,
      filter: "",
      currentPage: 1
    };
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getEvents() {
      apiService.getEvents().then(data => {
        this.events = data;
        this.numberOfEvents = data.length;
        this.totalRows = data.length;
        console.log("Count: " + this.numberOfEvents + ": " + this.events[0]);
      });
    },
    doNothing: function() {
      // Do nothing
      console.log("No");
    }
  },

  mounted() {
    this.getEvents();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>