/* eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <p class="my-4">
          <b-form-checkbox
            id="checkbox-2"
            v-model="filterBooked"
            @change="optionBookedChanged"
            name="checkbox-2"
            value="true"
            unchecked-value="false"
            switch
          >nur freie Personen anzeigen</b-form-checkbox>
          <b-form-checkbox
            id="checkbox-3"
            v-model="showFlexible"
            @change="optionFlexibleChanged"
            name="checkbox-3"
            value="true"
            unchecked-value="false"
            switch
          >"Ich bin flexibel" mit anzeigen</b-form-checkbox>
        </p>
      </b-col>
      <b-col>
        <b-form-select @change="workTypeChanged" v-model="selectedWorkType" :options="workTypes"></b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        {{ this.filteredPersons.length }} / {{ this.basePersonList.length }}
        <b-form-input
          type="search"
          @change="filterChanged"
          v-model="searchString"
          placeholder="Filtertext eingeben"
        ></b-form-input>
        <b-form-select
          @change="onChangeLeft"
          @dblclick="selectPerson"
          v-model="selectedPersonIdLeft"
          value-field="id"
          text-field="displayName"
          :options="filteredPersons"
          :select-size="14"
        ></b-form-select>
      </b-col>
      <b-col>
        <b>{{ activePerson.id }}: {{ activePerson.displayName }}</b>
        <br />
        <b>Alter:</b>
        &nbsp;<AgeHint :ageId="activePerson.age" />
        <br />
        <span v-if="activePerson.teamHead != null" style="color: red;">
          <b>Team-Head: {{ activePerson.teamHead.displayName }}</b>
          <br />
        </span>
        {{ activePerson.hint }}
        <br />
        <b-button
          class="btn btn-info"
          @click="addElement"
          :disabled="addValidation"
        >Einteilen&nbsp; &rarr; &nbsp;</b-button>&nbsp;
        <b-button
          class="btn btn-warning"
          @click="removeElement"
          :disabled="this.selectedPersons.length == 0"
        >Entfernen&nbsp; &larr; &nbsp;</b-button>
        <br />
        <br />
        <ul id="assignment-list">
          <li
            v-bind:key="assignment.id"
            v-for="assignment in activeAssignments"
          >{{ assignment.shiftAssignment.shift.eventPartName }} {{ assignment.shiftAssignment.shift.name }}: {{ assignment.shiftAssignment.shift.sectionName }} -> {{ assignment.shiftAssignment.positionName }}</li>
        </ul>
        <span v-if="activeAssignments.length == 0">
          <b>bisher keine Einteilung</b>
        </span>
      </b-col>
      <b-col>
        {{ this.selectedPersons.length }} / {{ shiftAssignment.count }}
        <b-form-select
          @change="onChangeRight"
          v-model="selectedPersonIdRight"
          value-field="id"
          text-field="displayName"
          :options="selectedPersons"
          :select-size="16"
        ></b-form-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
//import Vue from "vue";

import { APIService } from "../APIService";
import AgeHint from "../components/AgeHint.vue";

const apiService = new APIService();

export default {
  name: "PersonSelection",
  components: {AgeHint},
  props: ["section", "position", "shift", "shiftAssignment", "eventPart"],
  data: function() {
    return {
      filteredPersons: [], // Person object list actually displayed on the left
      basePersonList: [], // Person object list at person.id
      originalPersonList: [], // untouched orginally queried Person object list
      selectedPersons: [], // Person object list at 0...n
      originalSelected: [], // Person object list at 0...n
      selectedPersonIdLeft: null, // person.id selectedPersonIdLeft element left
      selectedPersonIdRight: "", // person.id selectedPersonIdLeft element right
      activePerson: {}, // Person object selectedPersonIdLeft/selectedPersonIdRight at element left/right
      activeAssignments: [], // persistent assignment of activePeson
      searchString: "",
      filterBooked: "true",
      showFlexible: "false",
      selectedWorkType: this.section.group.workType,
      workTypes: [
        { value: "kitchen", text: "Küche" },
        { value: "drinks", text: "Ausschank" },
        { value: "bar", text: "Bar" },
        { value: "coffee", text: "Kaffee/Kuchen" },
        { value: "service", text: "Essens- & Getränke Träger" },
        { value: "construction", text: "Auf- & Umbau" },
        { value: "flex", text: "Alle" }
      ]
    };
  },
  watch: {
    searchString: function(val) {
      //console.log("Typed: " + val);
      const filterVal = val.toLowerCase();
      this.filteredPersons = this.basePersonList.filter(person =>
        person.displayName.toLowerCase().includes(filterVal)
      );
    }
  },
  computed: {
    addValidation: function() {
      const result =
        (this.selectedPersons.length < this.shiftAssignment.count) &
        (this.selectedPersonIdLeft != null);
      return !result;
    }
  },
  methods: {
    addElement() {
      console.log("Add: " + this.selectedPersonIdLeft);

      // add entry to right list
      this.selectedPersons.push(
        this.originalPersonList[this.selectedPersonIdLeft]
      );
      this.$emit("childToParent", this.originalSelected, this.selectedPersons);

      // remove entry from left list
      this.basePersonList = this.basePersonList.filter(
        person => person.id != this.selectedPersonIdLeft
      );
      //this.basePersonList.splice(this.selectedPersonIdLeft, 1); //delete the section
      this.filteredPersons = this.filteredPersons.filter(
        person => person.id != this.selectedPersonIdLeft
      );

      //this.selectedPersonIdLeft = null;
    },
    removeElement() {
      console.log(
        "Remove: " + this.originalPersonList[this.selectedPersonIdRight]
      );

      // remove selected element from right list
      this.selectedPersons = this.selectedPersons.filter(
        person => person.id != this.selectedPersonIdRight
      );
      this.$emit("childToParent", this.originalSelected, this.selectedPersons);

      // re-add removed person to left list
      this.basePersonList.push(
        this.originalPersonList[this.selectedPersonIdRight]
      );
      this.filteredPersons.push(
        this.originalPersonList[this.selectedPersonIdRight]
      );
      this.filteredPersons.sort((a, b) => {
        const bandA = a.lastName.toUpperCase();
        const bandB = b.lastName.toUpperCase();

        let result = 0;
        if (bandA > bandB) {
          result = 1;
        } else if (bandA < bandB) {
          result = -1;
        }
        return result;
      });
    },
    onChangeLeft() {
      this.onChange(this.selectedPersonIdLeft);
    },
    onChangeRight() {
      this.onChange(this.selectedPersonIdRight);
    },
    onChange(val) {
      console.log("Active: " + val);
      this.activePerson = this.originalPersonList[val];
      console.log("List: " + this.originalPersonList[val]);
      apiService.getAssignmentsByPerson(this.activePerson.id).then(data => {
        this.$data.activeAssignments = data;
      });
    },
    optionFlexibleChanged(val) {
      this.loadData(this.filterBooked, val);
    },
    optionBookedChanged(val) {
      this.loadData(val, this.showFlexible);
    },
    workTypeChanged() {
      this.loadData(this.filterBooked, this.showFlexible);
    },
    selectPerson() {
      //console.log("Dbl-Select"); // dbl-click handler not working on list
    },
    restartForm() {
      //Object.assign(this.$data, this.getDefaultData());
    },
    filterChanged() {
      //console.log("Changed!");
    },
    loadData(doFilterBooked, doShowFlexible) {
      this.$data.basePersonList = [];
      apiService
        .getPersons(this.eventPart.id, "all", false, true) // all leads to all persons being fetched, regardless of its work type status (except none)
        .then(data => {
          data.forEach(person => {
            this.$data.originalPersonList[person.id] = person;
          });
          console.log("Person-Count (Org): " + data.length);
        });
      apiService
        .getPersons(
          this.eventPart.id,
          this.selectedWorkType,
          doFilterBooked,
          doShowFlexible
        )
        .then(data => {
          data.forEach(person => {
            // filter persistent items from the right in the left list
            const finding = this.$data.originalSelected.find(
              item => item.id == person.id
            );
            // filter non-persistent items from the right in the left list
            const finding2 = this.$data.selectedPersons.find(
              item => item.id == person.id
            );

            if (finding == null && finding2 == null) {
              this.$data.basePersonList.push(person);
            }

            //this.$data.originalPersonList[person.id] = person;
          });
          this.$data.filteredPersons = this.$data.basePersonList.slice();
          console.log("Person-Count: " + data.length);
        });
    }
  },
  created() {
    apiService.getAssignmentsByShift(this.shiftAssignment.id).then(data => {
      this.$data.originalSelected = data;
      this.$data.selectedPersons = data.slice(); // copy element references to new array
    });
    this.$emit("childToParent", this.originalSelected, this.selectedPersons);

    this.loadData(this.filterBooked, this.showFlexible);
  }
};
</script>