<template>
  <div>
    <h1>{{ this.$route.params.id }}: {{ personData.displayName }}
    <button size="sm" class="btn btn-warning" @click="editPerson(personData)">o</button>
    &nbsp;<img style="cursor: pointer" @click="getPersonalWorkplan(personData)" src="../assets/pdf-icon.png" width="35pt"/>
  </h1>
    <table class="table table-bordered table-hover table-striped">
      <thead class="thead-dark">
        <tr>
          <th>Wunsch</th>
          <th>Info</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Prio 1:</td>
          <td>{{ personData.thA }}</td>
        </tr>
        <tr>
          <td>Prio 2:</td>
          <td>{{ personData.thE }}</td>
        </tr>
        <tr>
          <td>Alter:  <AgeHint :ageId="personData.age" /></td>
          <td>Team-Head: {{ teamHead }}</td>
        </tr>
        <tr>
          <td>T-Shirt Schnitt: {{ gender }}</td>
          <td>T-Shirt Größe: {{ shirtSize }}</td>
        </tr>
        <tr>
          <td colspan="2">
            <b>Hinweise</b>
          </td>                    
        </tr>
        <tr>
          <td colspan="2" style="font-style: italic;">{{ personData.hint }}</td>                                        
        </tr>        
      </tbody>
    </table>
    <br />
    <div style="text-align: left;">&nbsp; Anzahl Teammitglieder: {{ members.length}}</div>
    <table class="table table-bordered table-hover table-striped">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Id</th>
          <th>Name</th>
          <th>Löschen?</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="member in members" :key="member.id">
          <td>{{ member.id }}</td>
          <td>{{ member.displayName }}</td>
          <td>
            <b-button
              class="btn btn-danger"
              icon
              v-confirm="{
                        loader: false,
                        ok: dialog => deleteMember(dialog, member),
                        cancel: doNothing,
                        message: member.lastName + member.firstName +' wirklich löschen?'}"
            >X</b-button>
          </td>
        </tr>
      </tbody>
    </table>
    <br/>    
    <div style="text-align: left;">&nbsp; Anzahl Einteilungen: {{ assignments.length}}</div>
    <table class="table table-bordered table-hover table-striped">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Veranstaltung</th>
          <th>Bereich</th>
          <th>Position</th>
          <th>Schicht</th>
          <th>Löschen?</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="assignment in assignments" :key="assignment.id">
          <td>{{ assignment.shiftAssignment.shift.eventPartName }}</td>
          <td>{{ assignment.shiftAssignment.shift.sectionName }}</td>
          <td>{{ assignment.shiftAssignment.positionName }}</td>
          <td>{{ assignment.shiftAssignment.shift.name }}</td>
          <td>
            <b-button
              class="btn btn-danger"
              icon
              v-confirm="{
                        loader: false,
                        ok: dialog => deleteAssignment(dialog, assignment),
                        cancel: doNothing,
                        message: assignment.shiftAssignment.positionName + ' wirklich löschen?'}"
            >X</b-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { APIService } from "../APIService";
import AgeHint from "../components/AgeHint.vue";

const apiService = new APIService();

export default {
  name: "PersonView",

  components: {AgeHint},

  data() {
    return {
      assignments: [],
      members: [],
      personData: null
    };
  },
  computed: {
    teamHead: function() {
      if (this.personData.teamHead != null) {
        return (
          this.personData.teamHead.id +
          ": " +
          this.personData.teamHead.lastName +
          " " +
          this.personData.teamHead.firstName
        );
      }
      return "-";
    },
    gender: function() {
      if (this.personData.gender == 0) {
        return ("männlich");
      } else if (this.personData.gender == 1) {
        return ("weiblich");
      }
      return "-";     
    },
    shirtSize: function() {
      switch(this.personData.size) {
        case "1": return "XS";
        case "2": return "S";
        case "3": return "M";
        case "4": return "L";
        case "5": return "XL";
        case "6": return "XXL";
        case "7": return "3XL";
        case "11": return "98/104";
        case "12": return "110/116";
        case "13": return "122/128";
        case "14": return "134/146";
        case "15": return "152/164";
        default: return "keins" + " " + this.personData.size;
      }
    }
  },
  methods: {
    doNothing: function() {
      // Do nothing
      console.log("No");
    },
    editPerson(person) {
      //console.log(person);
      this.$router.push({
        name: "register",
        params: {
          person: person
        }
      });
    }, 
    loadData() {
        apiService.getTeamMembers(this.$route.params.id).then(data => {
        this.members = data;
      });
      apiService.getAssignmentsByPerson(this.$route.params.id).then(data => {
        this.assignments = data;
      });
      apiService.getPersonById(this.$route.params.id).then(data => {
        this.personData = data;
      });
    },
    getPersonalWorkplan(personData) {
      apiService.getPersonalWorkplanExport(personData);
    },      
    deleteAssignment(dialog, asm) {
      apiService.deleteAssignmentById(asm.id).then(response => {
        if (response.status == 200) {
          const index = this.assignments.findIndex(post => post.id === asm.id); // find the section index
          if (~index)
            // if the post exists in array
            this.assignments.splice(index, 1); //delete the section
        }
      });
      dialog.close();
    },
    deleteMember(dialog, asm) {
      apiService.deletePerson(asm).then(response => {
        if (response.status == 200) {
          const index = this.members.findIndex(post => post.id === asm.id); // find the section index
          if (~index)
            // if the post exists in array
            this.members.splice(index, 1); //delete the section
        }
      });
      dialog.close();
    }    
  },
  created() {
    this.loadData();
  }
};
</script>