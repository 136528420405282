<template>
  <div class="about">
    <h1>
      <span v-if="selectedPart == ''">Einteilungsplan
        &nbsp;<img style="cursor: pointer" @click="getWorkplan()" src="../assets/pdf-icon.png" width="35pt"/>
      </span>
      <span v-if="getEventPart(selectedPart) != null">{{ getEventPart(selectedPart).text }}</span>
    </h1>

    <div class="container">
      <div class="row">
        <div class="col-sm">
          <b-form-select @change="partChanged" v-model="selectedPart" :options="eventPartOptions"></b-form-select>
        </div>
      </div>
    </div>
    <br />

    <table v-for="group in sectionGroups" :key="group.id" class="table table-bordered table-hover">
      <thead class="thead-dark">
        <tr>
          <th scope="col">{{ group.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="section in group.sections" :key="section.id">
          <th scope="row">
            <table class="table table-bordered table-hover">
              <thead class="thead-light">
                <tr>
                  <th scope="col">{{ section.name }}</th>
                  <th v-for="shift in section.shifts" :key="shift.id">{{shift.name}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="position in section.positions" :key="position.id">
                  <th scope="row">{{ position.name }}</th>
                  <td v-for="shift in position.shifts" :key="shift.id">
                    <span v-for="item in shift.assignments" :key="item.id">
                      <router-link
                        :to="{ name: 'person', params: { id: item.personId, name: item.lastName }}"
                      >
                        <span class="text-dark">{{ item.firstName }} {{ item.lastName }}</span><br />
                      </router-link>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { APIService } from "../APIService";

const apiService = new APIService();

export default {
  name: "WorkplanView2",

  components: {},

  data() {
    return {
      selectedPart: "",
      eventParts: [],
      eventPartOptions: [],
      sectionGroups: []
    };
  },

  methods: {
    getAssignments() {},
    getWorkplan() {
      apiService.getWorkplanExportFull(false);
    },      
    getEventParts() {
      apiService.getEventParts().then(data => {
        this.eventParts = data;
        data.forEach(eventPart => {
          this.eventPartOptions.push({
            value: eventPart.id,
            text: eventPart.name
          });
        });
      });
    },
    getEventPart() {
      return this.eventPartOptions.find(
        part => part.value == this.selectedPart
      );
    },
    partChanged() {
      apiService.getAssignmentsByPart(1, this.selectedPart).then(data => {
        this.sectionGroups = data;
      });
    }
  },

  created() {
    this.getEventParts();
  }
};
</script>
